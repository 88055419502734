import React, { useState } from "react";
import { loginBack, ForgotIcon } from "../../../helper/constant";
import { forgetPasswordCall } from "../../../service/auth.service";
import {useHistory} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import {Col, Row} from "reactstrap";

const ForgotPassword = (props) => {
    const [loadingModal, setLoadingModal] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const history = useHistory();
    const {REACT_APP_API_URL, REACT_APP_DOMAIN} = process.env;

    const loginContainer = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "fixed",
        overflow: "auto",
        top: 0,
        bottom: 0
    };

    const handleInputChange = (event) => {
        setEmail(event.target.value); // Mettre à jour la valeur de l'input dans l'état
      };
    
    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingModal(true);
        try {
            await forgetPasswordCall(`${REACT_APP_API_URL}/iam-service/password-new`, {email: email});
            setLoadingModal(false);
            history.push("/login");
        } catch (e) {
            setLoadingModal(false);
            console.log("" + e);
            setErrorMessage("Une erreur s'est produite. Veuillez essayer ultérieurement.");
        }
    }

    return (
        <div className="container-fluid" style={loginContainer}>
            <div className="form-container">
                <div className="login-icon">
                    <img src={ForgotIcon} alt="icon" height="100px"/>
                </div>
                <div className="login-title">Mot de passe oublié ?</div>
                { errorMessage ? <div class="text-center form-info-text plr-24 mt-16"><span style={{color: '#ff0000', paddingLeft: '1em'}}>{errorMessage}</span></div> : null}
                <div className="text-center form-info-text plr-24 mt-16">
                    Fournissez votre adresse e-mail pour réinitialiser votre mot de passe.
                    Si un compte existe pour cette adresse e-mail vous allez recevoir un lien permettant de réinitialiser votre mot de passe.
                </div>
                <form className="pa-24" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="email"
                            className="form-control react-form-input"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="email"
                            onChange={handleInputChange}
                            value={email}

                        />
                    </div>
                    <button type="submit" className="btn form-button">
                        Valider
                    </button>
                    <div
                        className="text-center link-label"
                        onClick={() => props.history.push("/login")}
                    >
                        Login ?
                    </div>
                </form>
            </div>
            <Modal
                show={loadingModal}
                size="sm"
                centered
                backdrop={"static"}
            >
                <div className="align-self-center ">
                    <Row className="pt-3">
                        <Col>
                            <Spinner animation="border" color="warning" role="status"
                                     style={{width: '5rem', height: '5rem'}}/>
                        </Col>
                    </Row>
                    <Row className="pt-3 pb-2"><Col>
                        <div className="display-5 c-text-dark" style={{fontWeight: "bold"}}>
                            Demande en cours ...
                        </div>
                    </Col></Row>
                </div>
            </Modal>
        </div>

    );
};

export default ForgotPassword;
