import axios from "axios";
import {getJWT} from "../helper/cookie";


export const getConfigObject = (jwt) => {
    return {
        headers: {
            'Authorization': jwt,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };

};


export async function loginCall(host, data) {
    const response = await axios.post(host, data, {
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    })
    return response.data;
}


export async function forgetPasswordCall(host, data) {
    const response = await axios.put(host, data, {
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    })
    return response.data;
}


export async function findUserByUsername(host, jwt) {
    const response = await axios.get(host, getConfigObject(jwt));
    return response.data;
}
