import React, {useEffect, useState, useRef} from "react";
import {
    FormGroup,
    Label,
    Row,
    CustomInput,
    DropdownToggle,
    Input
} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {paymentMethod} from "../../commun/enum/paymentMethod";

import Modal from "react-bootstrap/Modal";

const Buffer = require("buffer/").Buffer;


export default function UploadDocument({
                                           setUpload,errors,
                                           register, handleSubmit, setValue, upload, onSubmitUpload, errorMessage
                                       }) {
    const [errorMessagePayment, setErrorMessagePayment] = useState("");

    const [fileName, setFileName] = useState("");
    const [invalidFile, setInvalidFile] = useState(false);
    let fileReader;

    useEffect(() => {
        register({name: "name"});
        register({name: "type"});
        register({name: "lastModified"});
        register({name: "lastModifiedDateDocument"});
        register({name: "size"});
        register({name: "contents"});
    }, []);

    const onSubmitActionUpload = data => {
        console.log("in submit upload document ", data);
        onSubmitUpload(data);

    };

    const handleFileRead = e => {
        let contents = fileReader.result;
        setValue("contents", contents);
    };

    const handleFileChange = ({target: {files}}) => {
        const cancel = !files.length;
        if (cancel) return;

        const [{size, name}] = files;
        const maxSize = 1000000;

        if (size < maxSize) {
            setFileName(name);
            setInvalidFile(false);
        } else {
            setFileName("");
            setInvalidFile(true);
            return;
        }

        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsDataURL(files[0]);
        setValue("name", files[0].name);
        setValue("type", files[0].type);
        setValue("lastModified", files[0].lastModified);
        setValue("lastModifiedDateDocument", files[0].lastModifiedDate);
        setValue("size", files[0].size);
    };

    return (
        <Modal
            show={upload}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={"static"}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4 className="mb-0 fs-22 bold-text" style={{color:"#FF0B0E"}}><IntlMessages id="action.impo.fil"/> </h4>
                </Modal.Title>
            </Modal.Header>
            <div>
                <Row className="col-sm-12">
                    <Col className="col-sm-12">
                        <br/>
                        <div>
                            <form onSubmit={handleSubmit(onSubmitActionUpload)}>
                                <Row>
                                    <Col>
                                        <div className="display-5 c-text-danger">
                                            {errorMessagePayment}{" "}{errorMessage}
                                        </div>
                                    </Col>
                                </Row>
                                <br/>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="document.nom"/>
                                            <AdaInputText
                                                name="functionalType"
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="document.validity.date"/>
                                            <AdaInputText
                                                type="date"
                                                name="validityDate"
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="document.doc"/>
                                            <CustomInput
                                                type="file"
                                                id="exampleCustomFileBrowser"
                                                name="customFile"
                                                onChange={handleFileChange}
                                                invalid={invalidFile}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>




                                <Row className="col-sm-12 mt-3">
                                    <Col className="col-sm-8"/>
                                    <Col>
                                        <FormGroup>
                                            <AdaButton className="c-btn c-danger w-100" onClick={() => setUpload(false)}>
                                                <IntlMessages id="action.common.cancel"/>
                                            </AdaButton>

                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <AdaButton className="c-btn c-warning w-100" type="submit" style={{marginLeft: 10}}>
                                                <IntlMessages id="action.common.submit"/>
                                            </AdaButton>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal.Footer/>
        </Modal>
    );
}
