import React from 'react';
import PageTitle from '../components/common/PageTitle';
import axios from "axios";
import {Col, Row} from "reactstrap";
import {getConfigObject} from "../helper/cookie";
import {useHistory} from "react-router-dom";
import Cookies from 'universal-cookie';

export default function MenuOrganization(props) {

    const [organizations, setOrganizations] = React.useState([]);
    const {REACT_APP_API_URL, REACT_APP_DOMAIN} = process.env;
    const cookies = new Cookies();

    let history = useHistory();

    React.useEffect(() => {

        axios.get(`${REACT_APP_API_URL}/organization-service/organizations`, getConfigObject()).then(res => {
            setOrganizations(res.data);
            if(res.data && res.data.length === 1) {
                navigateToMenu(res.data[0])
            }
        });
    }, []);

    function navigateToMenu(org) {
        let now = new Date();
        let minutes = 60;
        now.setTime(now.getTime() + (minutes * 60 * 1000));
        cookies.set('organization', JSON.stringify(org), {domain: `${REACT_APP_DOMAIN}`, expires: now});
        history.push('/menu')
    }


    return (
        <div className="col-lg-12">

            <div className="col-lg-10">
                <PageTitle title="sidebar.search.org"/>
                <div className="col-lg-12">
                    <Row>
                        {
                            organizations.map((org, key) => (
                                <Col lg="4" key={`orga${key}`}>
                                    <div className="introduction">
                                        <div>
                                            <div
                                                className="whitelight pa-10 cursor-pointer with-transition roe-box-shadow pos-relative board-grid">
                                                <div className="board" style={{background: "#eeeeee"}}>
                                                    <div className="overlay flex-x center">
                                                        <div className="text-center">
                                                            <div
                                                                className="fs-20 bold-text px15 board-list-title"
                                                                style={{
                                                                    wordBreak: "break-all",
                                                                    padding: "0px 15px"
                                                                }}
                                                            >
                                                                <a href="#" onClick={() => navigateToMenu(org)}>{org.name}</a>
                                                                <div style={{color: "#3230d6", fontSize: 15}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>


        </div>
    );
}
