import React from "react";
import HeaderWrapper from "./header.style";
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import {
    friend1,
    friend2,
    friend3,
    people1,
    people2,
    people3,
    ProfileLockScreen
} from "../../helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "../../redux/auth/actions";
import { withRouter } from "react-router-dom";
import PopoverBlock from './PopoverBlock'
import {useHistory} from "react-router-dom";
import {logOut} from "../../helper/cookie";

const { logout } = AuthActions;

const Header = props => {
    let history = useHistory();

    const userSignout = () => {
        logOut();
    };

    return (
        <HeaderWrapper {...props}>
            <div className="headerBack">
                <div className="flex-x align-center">
                    <div className="drawer-handle-arrow">
                        <button
                            className="top-header-icon"
                            onClick={() => history.push(`/menuOrganization`)}
                        >
                            <i className="fas fa-bars"></i>
                        </button>
                    </div>
                    <div className="drawer-handle-arrow">
                        <button
                            className="top-header-icon"
                            onClick={() => history.push(`/menu`)}
                        >
                            <i className="fas fa-bars"></i>
                        </button>
                    </div>

                    <div className="pl-10 flex-1">
                    </div>
                    <div className="pl-10">
                        <div id="profile">
                            <img
                                className="top-header-profile-class"
                                    src={ProfileLockScreen}
                                alt="notify"
                            />
                        </div>
                        <UncontrolledPopover
                            className="roy-menu"
                            innerClassName="roy-inner-content"
                            placement="bottom-end"
                            target="profile"
                            trigger="legacy"
                        >
                            <PopoverBody>
                                <div
                                    className="roy-menu-list"
                                    onClick={() => props.history.push('/profile')}
                                >
                                   Mon profil
                                </div>
                                <div
                                    className="roy-menu-list"
                                    onClick={userSignout}
                                >
                                    Déconnexion
                                </div>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                </div>
            </div>
        </HeaderWrapper>
    );
};

export default compose(
    withRouter,
    connect(
        null,
        { logout }
    )
)(Header);
