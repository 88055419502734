import React from "react";
import {iconDemo, loginBack} from "../../../helper/constant";
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import AuthActions from "../../../redux/auth/actions";
import enhancer from "./enhancer/LoginFormEnhancer";
import {findUserByUsername, loginCall} from "../../../service/auth.service";
import Label from "reactstrap/es/Label";
import Cookies from 'universal-cookie';


const {login} = AuthActions;

const Login = props => {

    const [errorMessage, setErrorMessage] = React.useState("");
    const {REACT_APP_API_URL, REACT_APP_DOMAIN} = process.env;
    const cookies = new Cookies();

    const handleLogin = async e => {
        e.preventDefault();
        const {values, handleSubmit} = props;
        if (values.username !== "" && values.password !== "") {
            // appel aws
            const data = {
                username: values.username,
                password: values.password,
                origin: "application-inti"
            };
            let expires = new Date();
            let minutes = 60;
            expires.setTime(expires.getTime() + (minutes * 60 * 1000));

            try {
                const resultatLogin = await loginCall(`${REACT_APP_API_URL}/authentication-service/signin`, data);
                const token = {token: resultatLogin.accessToken};
                cookies.set('jwtToken', token.token, {domain: `${REACT_APP_DOMAIN}`, expires});
                //setCookie('jwtToken', token.token, { domain: 'erpadaming.fr' });

                const resultatUser = await findUserByUsername(`${REACT_APP_API_URL}/iam-service/users/by_username/${values.username}`, token.token);
                cookies.set('userInfos', JSON.stringify({id: resultatUser.id, firstName: resultatUser.firstName}), {
                    domain: `${REACT_APP_DOMAIN}`,
                    expires
                });

                try {
                    const employee = await findUserByUsername(`${REACT_APP_API_URL}/employee-service/employees/byUser/${resultatUser.id}`, token.token);
                    cookies.set('employeeConnected', JSON.stringify(employee), {
                        domain: `${REACT_APP_DOMAIN}`,
                        expires: expires
                    });
                } catch(e) {
                    console.log('Error retrieving employee', e);
                }
                props.login(token);
                if (!cookies.get('organization')) {
                    props.history.push("/menuOrganization");
                } else {
                    props.history.push("/menu");
                }

            } catch (error) {
                console.log('login error', error);

                setErrorMessage(error && error.response && error.response.data ? error.response.data.message : JSON.stringify(error))
            }
            handleSubmit();
        }
    }
    const {values, handleChange, handleBlur, errors, touched, submitCount} = props;

    const loginContainer = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "fixed",
        overflow: "auto",
        top: 0,
        bottom: 0,
    };

    const Error = props => {
        const field1 = props.field;
        if ((errors[field1] && touched[field1]) || submitCount > 0) {
            return (
                <span className={props.class ? props.class : "error-msg"}>
                    {errors[field1]}
                </span>
            );
        } else {
            return <span/>;
        }
    };

    return (
        <div className="container-fluid" style={loginContainer}>
            <div className="form-container">
                <div className="login-icon">
                    <img src={iconDemo} alt="icon" height="50x"/>
                </div>
                <div className="login-title">Connectez-vous à votre compte</div>
                <form className="pa-24" onSubmit={handleLogin}>

                    <Label>
                        <p style={{color: "red"}}> {errorMessage}</p>
                    </Label>

                    <div className="form-group">
                        <label>Login</label>
                        <input
                            type="text"
                            className="form-control react-form-input"
                            id="username"
                            onChange={handleChange}
                            value={values.username}
                            onBlur={handleBlur}
                            placeholder="Email"
                        />
                        <Error field="username"/>
                    </div>

                    <div className="form-group">
                        <label>Mot de passe</label>
                        <input
                            type="password"
                            className="form-control react-form-input"
                            id="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Password"
                        />
                        <Error field="password"/>
                    </div>

                    <button type="submit" className="btn form-button">
                        Valider
                    </button>
                    <div
                        className="text-center link-label"
                        onClick={() => props.history.push("/forgotPassword")}
                    >
                        Mot de passe oublié ?
                    </div>
                </form>
            </div>
        </div>
    );
};

export default compose(
    withRouter,
    enhancer,
    connect(
        null,
        {login}
    )
)(Login);
