import React, {useEffect, useState} from "react";
import {CustomInput, FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import InputMask from "react-input-mask";
import * as service from "../../../service/crud.service";
import {getHostByAppName, NOMENCLATURE_NAME, ORGANIZATION_NAME} from "../../../service/host";
import AdaSelectBuilder from "../../../components/ada.select.builder";

export default function InformationUpdate({register, setValue, errors, employee, edit, dispatch, control}) {
    const [functionList, setFunctionList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);

    useEffect(() => {
        register({name: "firstName"}, {required: "Ce champ est obligatoire."});
        register({name: "lastName"}, {required: "Ce champ est obligatoire."});
        register({name: "socialSecurityNumber"});

        service.getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `organizations`, setOrganizationList)

        dispatch({"function":employee.function ? employee.function : undefined,
            "category": employee.category ? employee.category : undefined,
            "organization": employee.organization ? employee.organization : undefined,
            "mutual": employee.otherDataEmployeeType && employee.otherDataEmployeeType.mutual ? employee.otherDataEmployeeType.mutual : undefined,
            "experienceLevel" : employee.skillEmployeeType && employee.skillEmployeeType.experienceLevel ? employee.skillEmployeeType.experienceLevel : undefined})
    }, []);


    return (
        <div>

            <FormGroup>
                <div>
                    <Row>
                        <Col
                            className="col-sm-2"
                            style={{"margin-top": "10px"}}
                        >
                            <CustomInput
                                id="mme"
                                type="radio"
                                name="civility"
                                value="Mme"
                                defaultChecked={employee !== undefined ? (employee.civility === "Mme" ? true : false) : true}
                                innerRef={register}
                                label={
                                    <IntlMessages id="employee.civilite.mme"/>
                                }
                            />

                            <CustomInput
                                id="mr"
                                type="radio"
                                name="civility"
                                defaultChecked={employee !== undefined ? (employee.civility === "Mr" ? true : false) : false}
                                value="Mr"
                                innerRef={register}
                                label={
                                    <IntlMessages id="employee.civilite.mr"/>
                                }
                            />
                        </Col>

                        <Col
                            className="col-sm-2"
                            style={{"margin-top": "20px"}}
                        >
                            <FormGroup>
                                <CustomInput
                                    type="switch"
                                    id="enableContactClt"
                                    name="enable"
                                    defaultChecked={employee !== undefined ? employee.enable : true}
                                    innerRef={register}
                                    label={<IntlMessages id="employee.statut"/>}
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <IntlMessages id="assignment.organization"/>

                                <AdaSelectBuilder errors={errors} register={register}
                                                  setValue={setValue}
                                                  uri={`${getHostByAppName(ORGANIZATION_NAME)}/organizations/`}
                                                  inputName={"organization"}
                                                  optionsType={"string"}
                                                  defaultId={employee.organization}
                                                  control={control}
                                                  dispatch={dispatch}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.nom"/>
                        <AdaInputText
                            name="firstName"
                            defaultValue={employee !== undefined ? employee.firstName : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.prenom"/>
                        <AdaInputText
                            name="lastName"
                            defaultValue={employee !== undefined ? employee.lastName : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.telephone"/>
                        <AdaInputText
                            name="contactInformation.phone"
                            defaultValue={employee !== undefined && employee.contactInformation? employee.contactInformation.phone : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.contacts.mobile"/>
                        <AdaInputText
                            name="contactInformation.mobile"
                            defaultValue={employee !== undefined && employee.contactInformation ? employee.contactInformation.mobile : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.function"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_functions/`}
                                          inputName={"function"}
                                          optionsType={"string"}
                                          defaultId={employee.function}
                                          control={control}
                                          dispatch={dispatch}/>

                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.category"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_categorys/`}
                                          inputName={"category"}
                                          optionsType={"string"}
                                          defaultId={employee.category}
                                          control={control}
                                          dispatch={dispatch}/>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.skills.years"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/experience_levels/`}
                                          inputName={"experienceLevel"}
                                          optionsType={"string"}
                                          defaultId={employee.skillEmployeeType && employee.skillEmployeeType.experienceLevel ? employee.skillEmployeeType.experienceLevel : null}
                                          control={control}
                                          dispatch={dispatch}/>

                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.mutual"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/mutuals/`}
                                          inputName={"mutual"}
                                          optionsType={"string"}
                                          defaultId={employee.otherDataEmployeeType && employee.otherDataEmployeeType.mutual ? employee.otherDataEmployeeType.mutual : null}
                                          control={control}
                                          dispatch={dispatch}/>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.contacts.email"/>
                        <AdaInputText
                            name="contactInformation.email"
                            defaultValue={employee !== undefined && employee.contactInformation ? employee.contactInformation.email : ""}
                            type="email"
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.security.number"/>
                        <AdaInputText
                            type="text"
                            name="socialSecurityNumber"
                            defaultValue={employee !== undefined ? employee.socialSecurityNumber : ""}
                            mask="9 99 99 99 999 999 99"
                            maskChar=" "
                            errors={errors}
                            innerRef={register}
                            tag={InputMask}
                            onChange={e => setValue("socialSecurityNumber", e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>

                <Col>
                    <FormGroup>
                        <IntlMessages id="medical.date"/>
                        <AdaInputText
                            type="date"
                            name="otherDataEmployeeType.medicalExaminationDate"
                            defaultValue={employee !== undefined && employee.otherDataEmployeeType ? employee.otherDataEmployeeType.medicalExaminationDate : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>

                <Col >
                    <FormGroup>
                        <IntlMessages id="cityMobility"/>
                        <AdaInputText
                            type="text"
                            name="otherDataEmployeeType.cityMobility"
                            defaultValue={employee !== undefined && employee.otherDataEmployeeType ? employee.otherDataEmployeeType.cityMobility : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col className="mt-4">
                    <CustomInput
                        id="vehicle"
                        type="checkbox"
                        name="otherDataEmployeeType.vehicle"
                        defaultChecked={employee !== undefined && employee.otherDataEmployeeType ? employee.otherDataEmployeeType.vehicle : false}
                        innerRef={register}
                        label={
                            "Véhicule"
                        }
                    />
                </Col>

                <Col className="mt-4">
                    <CustomInput
                        id="available"
                        type="checkbox"
                        name="otherDataEmployeeType.available"
                        defaultChecked={employee !== undefined && employee.otherDataEmployeeType ? employee.otherDataEmployeeType.available : false}
                        innerRef={register}
                        label={
                            "Disponibilité"
                        }
                    />
                </Col>
                <Col className="mt-4">
                        <CustomInput
                            id="internal"
                            type="checkbox"
                            name="otherDataEmployeeType.internal"
                            defaultChecked={employee !== undefined && employee.otherDataEmployeeType ? employee.otherDataEmployeeType.internal : false}
                            innerRef={register}
                            label={
                                "Interne"
                            }
                        />
                    </Col>

                </Row>


        </div>
    );
}
