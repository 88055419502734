
import authAction from './actions'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const initState = {
    isLogin: cookies.get('jwtToken'),
    accessToken: localStorage.getItem('accessToken')
}


export default function rootReducer(state = initState, action) {

    switch (action.type) {
        case authAction.LOGIN:
            return {
                ...state,
                isLogin: action.isLogin,
                accessToken: action.accessToken,
            }
        case authAction.LOGOUT:
            return {
                ...state,
                isLogin: action.isLogin,
                accessToken: null
            }
        default:
            return state
    }
}