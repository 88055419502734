import Layout from "../layouts/DashboardLayout.jsx";
import {
  Login,
  Register,
  ForgotPassword
} from '../views/pages/index';
import Error400 from "../views/pages/error/Error400";
import Validate from "../views/pages/authentication/Validate";
import ResetPassword from "../views/pages/authentication/ResetPassword.jsx";

const indexRoutes = [
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/validate", component: Validate },
  { path: "/reset_password", component: ResetPassword },
  { path: "/forgotPassword", component: ForgotPassword },
  { path: "/", component: Layout },
  { path: "/maintenance", component: Error400}
];

export default indexRoutes;