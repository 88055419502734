import React, {useEffect, useState} from "react";
import Gauge from "react-svg-gauge";
import UserInfoWrapper from "./userinfo.style";
import * as service from "../../../service/crud.service";
import {getHostByAppName, NOMENCLATURE_NAME, ORGANIZATION_NAME} from "../../../service/host";

const LatestActivity = ({icon, title, email, telephone, statut, categ, func, organ, secnum, mut, experience, code }) => {

  const [companyFunction, setCompanyFunction] = useState({})
  const [companyCategory, setCompanyCategory] = useState({})
  const [organization, setOrganization] = useState({})
  const [mutuals, setMutuals] = useState({})
  const [experienceLevels, setExperienceLevels] = useState({})

  useEffect(()=>{
    if(categ){
      service.getById(
          getHostByAppName(NOMENCLATURE_NAME),
          `company_categorys`,
          setCompanyCategory,
          error => console.log(error), categ
      );
    }
  },[categ])

  useEffect(()=>{
    if(experience){
      service.getById(
          getHostByAppName(NOMENCLATURE_NAME),
          `experience_levels`,
          setExperienceLevels,
          error => console.log(error), experience
      );
    }
  },[experience])

  useEffect(()=>{
    if(func){
      service.getById(
          getHostByAppName(NOMENCLATURE_NAME),
          `company_functions`,
          setCompanyFunction,
          error => console.log(error), func
      );
    }
  },[func])

  useEffect(()=>{
    if(organ){
      service.getById(
          getHostByAppName(ORGANIZATION_NAME),
          `organizations`,
          setOrganization,
          error => console.log(error), organ
      );
    }
  },[organ])

  useEffect(()=>{
    if(mut){
      service.getById(
          getHostByAppName(NOMENCLATURE_NAME),
          `mutuals`,
          setMutuals,
          error => console.log(error), mut
      );
    }
  },[mut])


  return (
    <UserInfoWrapper className="fill-height pt-2">
      <div className="card roe-shadow-2 fill-height">
        <div className="card-body">

          <div className="row">
            <div className="col-md-4 aligner-wrapper text-center">
              <img
               src={icon}
               style={{width:"180px"}}
              />
              <div style={{marginTop:"10px"}}>
                <h3 className="card-title fs-15 bold-text">{title}</h3>
              </div>
              <small className="text-muted ml-3">Matricule : <b>{code}</b></small>
            </div>
            <div className="col-md-4 legend-wrapper">
              <div className="d-flex flex-column justify-content-center border-bottom py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-15 bold-text" >{organization.name}</h4>
                </div>
                <small className="text-muted ml-3">Organisation</small>
              </div>

              <div className="d-flex flex-column justify-content-center border-bottom py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-info mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-15 bold-text">{telephone}</h4>
                </div>
                <small className="text-muted ml-3">Téléphone</small>
              </div>

              <div className="d-flex flex-column justify-content-center border-bottom py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-info mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-15 bold-text">{secnum}</h4>
                </div>
                <small className="text-muted ml-3">Numéro de securité sociale</small>
              </div>

              <div className="d-flex flex-column justify-content-center border-bottom py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-success mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-15 bold-text">{email}</h4>
                </div>
                <small className="text-muted ml-3">Email</small>
              </div>
              <div className="d-flex flex-column justify-content-center py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-success mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-15 bold-text">{experienceLevels.name}</h4>
                </div>
                <small className="text-muted ml-3">Niveau d'expériance</small>
              </div>
            </div>

            <div className="col-md-4 legend-wrapper">
              <div className="d-flex flex-column justify-content-center border-bottom py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-15 bold-text">{companyCategory && companyCategory.name ? companyCategory.name : "ND"}</h4>
                </div>
                <small className="text-muted ml-3">Catégorie</small>
              </div>
              <div className="d-flex flex-column justify-content-center border-bottom py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-info mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-15 bold-text">{companyFunction && companyFunction.name ? companyFunction.name : "ND"}
                  </h4>
                </div>
                <small className="text-muted ml-3">Fonction</small>
              </div>
              <div className="d-flex flex-column justify-content-center border-bottom py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-success mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-15 bold-text">{statut}</h4>
                </div>
                <small className="text-muted ml-3">Statut</small>
              </div>

              <div className="d-flex flex-column justify-content-center py-3 mt-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="dot-indicator bg-success mt-1 mr-2"></div>
                  <h4 className="mb-0 text-dark fs-15 bold-text">{mutuals.name}</h4>
                </div>
                <small className="text-muted ml-3">Mutuelle</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserInfoWrapper>
  );
};

export default LatestActivity;
