import styled from 'styled-components';

const HeaderWrapper = styled.div`
    .headerBack{
        background: ${props => props.topbarTheme.backgroundColor};
        padding: 15px 10px;
        margin-left: -${props => (props.themeSetting.toolbarAlignValue === 'above' && !props.mini) ?
    props.drawerWidth :
    (props.themeSetting.toolbarAlignValue === 'above' && props.mini) ?
        props.miniDrawerWidth === props.drawerWidth ? '80px' : props.miniDrawerWidth : '0px'
    };
        @media  only screen and (max-width: 991.98px) {
            margin-left : 0px !important;
        }

        .drawer-handle-arrow {
            @media  only screen and (max-width: 991.98px) {
                display: none;
            }
        }

        .mini-drawer-menu-icon {
            margin: 6px 0;
            justify-content: center;
            cursor: pointer;
            display: none;
            color: ${props => props.topbarTheme.textColor};
            @media  only screen and (max-width: 991.98px) {
                display: block;
            }
            i {
                font-size: 20px;
                margin-right: 10px;
                position: relative;
                top: 2px;
            
            }
            .app-name {
                font-weight: 600;
            }
        }

        .top-header-profile-class {
            width: 35px;
            height: 35px;
            border-radius: 50%;
        }

        .top-header-icon {
            padding: 8px;
            border-radius: 50%;
            cursor: pointer;
            width: 35px;
            height: 35px;
            border: 0;
            transition: all 0.3s ease-in;
            background: ${props => props.topbarTheme.buttonBackColor};
            color: ${props => props.topbarTheme.buttonColor};
            display: flex !important;
            align-items: center;
            justify-content: center;
            position relative;
            .button-badge {
                background: #b71c1c;
                color: ${props => props.sidebarTheme.activeRouteTextColor};
                width: 20px;
                height: 20px;
                position: absolute;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -6px;
                right: -8px;
            }
            &:focus {
                outline: 0;
            }
            @media  only screen and (max-width: 575.98px) {
                width: 32px;
                height: 32px;
                padding: 6px;
                margin-top: 2px;
            }
            i {
                font-size: 16px;
                @media  only screen and (max-width: 575.98px) {
                    font-size: 13px;
                }
            }
        }
    }
`;

export default HeaderWrapper