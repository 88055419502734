import React, {useEffect, useState} from "react";

import {AdaButton} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import * as service from "../../../service/crud.service";
import {EMPLOYEE_NAME, getHostByAppName} from "../../../service/host";
import UploadDocument from "./document.upload";


export default function UploadAction({
                                         setObjectResource,
                                         setModalSuccessShow,
                                         objectResource,
                                         setErrorMessage, context
                                     }) {
    const [upload, setUpload] = useState(false);
    const {register, handleSubmit, errors, setValue} = useForm();

    const onSubmitUpload = data => {
        console.log("in submit upload document ", data);
        service.post(
            getHostByAppName(EMPLOYEE_NAME),
            `documents`,
            callbackRes,
            callbackError,
            {
                ...data, relatedTo : objectResource.id
            }
        );
    };

    const callbackRes = res => {
        console.log(res.data);
        setUpload(false);
        setModalSuccessShow(true);
    };

    const callbackError = error => {
        setErrorMessage(error.message);
        console.log("error to print", error.message);
    };

    const action = () => {
        setUpload(true)
        setModalSuccessShow(false);
    };

    return (
        <div>
            <AdaButton
                className="more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                onClick={() => action()}
            >
                <i className="far fas fa-paperclip" style={{fontSize: 12}}> Joindre un fichier</i>
            </AdaButton>

            <UploadDocument
                setUpload={setUpload}
                upload={upload}
                onSubmitUpload={onSubmitUpload}
                register={register}
                setValue={setValue} errors={errors}
                handleSubmit={handleSubmit}
            />
        </div>
    );
}
