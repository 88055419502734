import Intro from "../views/Intro";
import Menu from "../views/Menu";
import Users from "../views/Users/User";
import UserEdit from "../views/Users/UserEdit";
import Maintenance from "../views/pages/error/maintenance";
import Validate from "../views/pages/authentication/Validate";
import MenuOrganization from "../views/MenuOrganization";
import Settings from "../views/pages/settings/Settings";
import AdaEmployeeUpdate from "../views/pages/updateEmployee/AdaEmployeeUpdate";
import ResetPassword from "../views/pages/authentication/ResetPassword";

const dashboardRoutes = [
  { path: "/menu", component: Menu },
  { path: "/menuOrganization", component: MenuOrganization },
  { path: "/validate", component: Validate },
  { path: "/reset_password", component: ResetPassword },
  { path: "/intro", component: Intro },
  { path: "/users", component: Users },
  { path: "/users/add", component: UserEdit },
  { path: "/users/edit/:id", component: UserEdit },
  { path: "/profile", component: AdaEmployeeUpdate },
  { path: "/settings", component: Settings },
  { path: "/maintenance", component: Maintenance}
];

export default dashboardRoutes;