import React, {useEffect, useState} from "react";
import {loginBack, iconDemo} from "../../../helper/constant";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import enhancer from "./enhancer/RegisterFormEnhancer";
import axios from "axios";
import Label from "reactstrap/es/Label";
import {useHistory} from "react-router-dom";
import {CustomInput, FormGroup} from "reactstrap";
import {AdaInputText} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";

const ResetPassword = props => {

    let history = useHistory();
    let fileReader;

    const {register, handleSubmit, errors, setValue} = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [contents, setContents] = React.useState("");


    const [invalidFile, setInvalidFile] = useState(false);
    const [fileName, setFileName] = useState("");

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tokenAccount = params.get('tokenAccount');
    const username = params.get('username');
    const id = params.get('id');

    useEffect(() => {
        register({name: "name"});
        register({name: "type"});
        register({name: "lastModified"});
        register({name: "lastModifiedDateDocument"});
        register({name: "size"});
        register({name: "contents"});
        register({name: "password1"});
        register({name: "password2"});
    }, []);


    const onSubmit = data => {
        if (data.password1 === data.password2) {

            // change passsord
            axios
                .put(`${process.env.REACT_APP_API_URL}/iam-service/users/validate_user/${username}`,
                    {
                        "newPassword": data.password1
                    },
                    {
                        headers: {
                            'Authorization': tokenAccount,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        }
                    })
                .then(res => {
                    history.push(`/login`);
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMessage(error.toString());
                });
            props.history.push("/login");
        }
    };

    const loginContainer = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "fixed",
        overflow: "auto",
        top: 0,
        bottom: 0
    };


    return (
        <div className="container-fluid" style={loginContainer}>
            <div className="form-container">
                <div className="login-icon">
                    <img src={iconDemo} alt="icon" height="100px"/>
                </div>
                <div className="login-title">Reinitialisation de mot de passe</div>
                <form className="pa-24" onSubmit={handleSubmit(onSubmit)}>
                    <Label>
                        <p style={{color: "red"}}> {errorMessage}</p>
                    </Label>
                    {contents && (<div>
                        <img
                            src={contents}
                            style={{width: "180px"}}
                        />
                    </div>)}

                    <FormGroup>
                        Définir un nouveau mot de passe
                        <AdaInputText
                            name="password1"
                            errors={errors}
                            innerRef={register}
                            type="password"
                        />
                    </FormGroup>
                    <FormGroup>
                        Définir un nouveau mot de passe
                        <AdaInputText
                            name="password2"
                            errors={errors}
                            innerRef={register}
                            type="password"
                        />
                    </FormGroup>


                    <button type="submit" className="btn form-button">
                        Valider
                    </button>
                    <div
                        className="text-center link-label"
                        onClick={() => props.history.push("/login")}
                    >
                        Login ?
                    </div>
                </form>
            </div>

        </div>
    );
};

export default compose(
    withRouter,
    enhancer
)(ResetPassword);
