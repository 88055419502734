import React from "react";
import axios from "axios";
import {getConfigObject} from "@adaming/ada-react-component";
// import {getConfigObject} from "../helper/cookie";
export const get = (host, context, callbackRes, callbackError) => {

    axios.get(`${host}/${context}`, getConfigObject()).then(res => {
        callbackRes(res.data);
    }).catch(function (error) {
        callbackError(error);
    });
};

export const getUri = async (uri) => {
    const response = await axios.get(uri, getConfigObject());
    return response;
};

export const getById = (host, context, callbackRes, callbackError, id) => {

    axios.get(`${host}/${context}/${id}`, getConfigObject()).then(res => {
        callbackRes(res.data);
    }).catch(function (error) {
        callbackError(error);
    });
};

export const search = (host, context, callbackRes, callbackError, resource) => {

    axios.put(`${host}/${context}`, resource, getConfigObject()).then(res => {
        callbackRes(res.data);
    }).catch(function (error) {
        callbackError(error);
    });
};

export const post = (host, context, callbackRes, callbackError, resource) => {

    axios.post(`${host}/${context}`, resource, getConfigObject()).then(res => {
        callbackRes(res);
    }).catch(function (error) {
        callbackError(error);
    })
};

export const put = (host, context, callbackRes, callbackError, resource) => {

    axios.put(`${host}/${context}/${resource.id}`, resource, getConfigObject()).then(res => {
        callbackRes(res);
    }).catch(function (error) {
        callbackError(error);
    });
};

export const putSpe = (host, context, callbackRes, callbackError, resource, finallyExec) => {

    axios.put(`${host}/${context}`, resource, getConfigObject()).then(res => {
        callbackRes(res);
    }).catch(function (error) {
        callbackError(error);
    }).finally(()=>{
        finallyExec();
    });
};

export const deletes = (host, context, callbackRes, callbackError, resources) => {

    axios.put(`${host}/${context}/deletes`, resources, getConfigObject())
        .then(res => {
            callbackRes(res);
        }).catch(function (error) {
        callbackError(error.toString());
    })
};

export const getAllForSelect = (host, context, setState, state) => {
    let listForSelect = [];
    console.log("get ", context);
    axios.get(`${host}/${context}`, getConfigObject()).then(res => {
        if (res.data !== undefined && Array.isArray(res.data)) {
            res.data.forEach(item => listForSelect.push({
                key: item.id,
                label: item.name,
                value: item.id,
                object: item
            }));
            console.log(context, listForSelect)
            if (state !== undefined) {
                listForSelect.concat(state);
            }
            setState(listForSelect)
        }

    }).then(error => {
            return error;
        }
    );
};

export const patch = (host, context, callbackRes, callbackError, resource) => {
    if (resource.id !== undefined) {
        console.log(`${host}/${context}/${resource.id}`)
        axios.patch(`${host}/${context}/${resource.id}`, resource, getConfigObject()).then(res => {
            callbackRes(res);
        }).catch(function (error) {
            callbackError(error);
        });
    }
};

export const enable = (host, context, callbackRes, callbackError, resources, trueOrFalse) => {

    axios.put(`${host}/${context}/enable/${trueOrFalse}`, resources, getConfigObject())
        .then(res => {
            callbackRes(res);
        }).catch(function (error) {
        callbackError(error.toString());
    })
};
