import React, {useEffect, useState} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {familySituation} from "../../commun/enum/familySituation";
import * as service from "../../../service/crud.service";
import {getHostByAppName, NOMENCLATURE_NAME, ORGANIZATION_NAME} from "../../../service/host";
import AdaSelectBuilder from "../../../components/ada.select.builder";
import {Controller} from "react-hook-form";

export default function CivilityUpdate({register, setValue, errors, employee, edit, control, dispatch}) {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [countryList, setCountryList] = useState([]);
    const [familySituationList, setFamilySituationList] = useState([]);
    const [familySituationSelected, setFamilySituationSelected] = useState({});
    const [nativeCountrySelected, setNativeCountrySelected] = useState({});
    const [nationalitySelected, setNationalitySelected] = useState({});

    useEffect(() => {
        service.getAllForSelect(getHostByAppName(NOMENCLATURE_NAME), `countrys`, callbackCountry)
        service.getAllForSelect(getHostByAppName(NOMENCLATURE_NAME), `marital_statuss`, setFamilySituationList)

        dispatch({
            birthCountry : employee && employee.civilState.birthCountry ? employee.civilState.birthCountry : undefined,
            familySituation : employee && employee.civilState.familySituation ? employee.civilState.familySituation : undefined,
            nationalities : employee && employee.civilState.nationalities ? employee.civilState.nationalities : undefined
        })
    }, []);


    const callbackCountry = data =>{
        setCountryList(data)
        let tabSelected = []
        console.log("employee.civilState.nationalities",employee.civilState.nationalities)
        if(employee && employee.civilState && employee.civilState.nationalities && employee.civilState.nationalities.length > 0){
            tabSelected = data.filter(obj => employee.civilState.nationalities.includes(obj.value))
        }
        console.log("tabSelected",tabSelected)
        setValue(
            "nationalities",tabSelected
        );
    }


    const handleChangeNationality = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("nationality", selectedOption);
        dispatch({ nationalities : selectedOption ? selectedOption.map(obj => obj.value) : []})
    };

    return (
        <div>
            <Label>
                <p style={{color: "red"}}> {errorMessage}</p>
            </Label>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.birthDate"/>
                        <AdaInputText
                            type="date"
                            defaultValue={employee !== undefined && employee.civilState? employee.civilState.birthDate : ""}
                            name="civilState.birthDate"
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.nativeCountry"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/countrys/`}
                                          inputName={"birthCountry"}
                                          optionsType={"string"}
                                          defaultId={employee.civilState.birthCountry}
                                          control={control}
                                          dispatch={dispatch}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.birthCity"/>
                        <AdaInputText
                            name="civilState.birthCity"
                            defaultValue={employee !== undefined  && employee.civilState ? employee.civilState.birthCity : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.birthName"/>
                        <AdaInputText
                            name="civilState.birthName"
                            defaultValue={employee !== undefined  && employee.civilState ? employee.civilState.birthName : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.familySituation"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/marital_statuss/`}
                                          inputName={"familySituation"}
                                          optionsType={"string"}
                                          defaultId={employee.civilState.familySituation}
                                          control={control}
                                          dispatch={dispatch}/>
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.nationality"/>

                        <Controller
                            as={<AdaSelect name={"nationalities"} />}
                            name={"nationalities"}
                            isMulti
                            isClearable
                            errors={errors}
                            options={countryList}
                            onChange={handleChangeNationality}
                            control={control}
                            innerRef={register}
                        />



                    </FormGroup>
                </Col>
            </Row>


        </div>
    );
}
