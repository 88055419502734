import React, {Fragment, useState} from "react";
import "../updateEmployee/AdaEmployeeUpdate.scss";
import {Col, FormGroup, Label, Row} from "reactstrap";

import IntlMessages from "../../../helpers/intlMessages";
import {useForm} from "react-hook-form";
import {AdaButton, getCurrentEmployee} from "@adaming/ada-react-component";

import GenericSuccessModal from "../../commun/genericSuccesModal";
import Accordion from "./Accordion";
import {country} from "../../commun/enum/country";
import {familySituation} from "../../commun/enum/familySituation";
import EmployeeContract from "../details/contract";
import EmployeeBank from "../details/bank";
import EmployeeCivility from "../details/civility";
import InformationUpdate from "./informationUpdate";

import {AdaAddress, AdaBankDetail} from "@adaming/ada-react-business-component";
import CivilityUpdate from "./civilityUpdate";
import BankModelEmployee from "../model/bank.model";
import UpdateContract from "./updateContract";
import LatestActivity from "./UserInfoDoughnutWidget";
import {addressPostal, etatcivil, identity, infoban, infor, person} from "../../../helpers/constants";
import WrapperInformation from "./wrapper";
import EmployeeContactsUpdate from './employeeContactsUpdate'
import EmployeeDiplomas from './diplomas.employee'
import * as service from '../../../service/crud.service'
import {EMPLOYEE_NAME, getHostByAppName} from "../../../service/host";
import Documents from "./employee.documents";
import UploadAction from "./action.upload";
import DisplayInformationStyle from "./displayInformationStyle";
import EmployeeSkills from "./employee.skills";

const context = "employees";
const AdaEmployeeUpdate = props => {
    const Id = getCurrentEmployee().id;
    const [employee, setEmployee] = useState({});
    const [selectObject, setSelectObject] = useState({});

    const [isLoading, setIsLoading] = React.useState(false);
    const [modal, setModal] = useState(false);

    const [status, setStatus] = useState("plus");
    const [collapse, setCollapse] = useState(true);
    const [collapse1, setCollapse1] = useState(false);
    /*Edit mode */
    const [editInformation, setEditInformation] = useState(false);
    const [editIF, setEditIF] = useState(false);

    const [editCivility, setEditCivility] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [editBankDetails, setEditBankDetails] = useState(false);
    const [editContact, setEditContact] = useState(false);
    const [editSkills, setEditSkills] = useState(false);
    const [editDiplomas, setEditDiplomas] = useState(false);
    const [editDocuments, setEditDocuments] = useState(false);
    const [editContract, setEditContract] = useState(false);
    const [editBK, setEditBK] = useState(false);
    const [countryList, setCountryList] = useState(country);
    const [familySituationList, setFamilySituationList] = useState(
        familySituation
    );
    /*Register form */
    const {register, handleSubmit, errors, setValue, control} = useForm();
    const [activityAreaList, setActivityAreaList] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [values, setReactSelect] = React.useState({
        selectedOption: []
    });
    const [listContacts, setListContacts] = React.useState([]);

    const fetchData = async () => {
        setIsLoading(true);
        const result = await service.getUri(`${getHostByAppName(EMPLOYEE_NAME)}/${context}/${Id}`);
        console.log(result.data);
        setEmployee(result.data);
        setIsLoading(false);
    };

    React.useEffect(
        () => {
            if (Id !== undefined) {
                fetchData();
            }
        },
        [Id]
    );

    const dispatchSelectObject = (data) =>{
        console.log("in dispatch", {...selectObject, ...data})
        setSelectObject({...selectObject, ...data})
    }

    const handleChangeCountry = selectedOption => {
        console.log("nativeCountry", selectedOption);
        if (selectedOption !== null) {
            setValue("nativeCountry", selectedOption.value);
        } else {
            setValue("nativeCountry", undefined);
        }
    };
    const deleteElementFromList = (key, list) => {
        console.log("key", key);
        let arr = [];
        list.forEach((value, keyLoop) => {
            if (key !== keyLoop) {
                arr.push(value);
            }
        });
        return arr;
    };
    const handleChangeFamilySituation = selectedOption => {
        console.log("familySituation", selectedOption);
        if (selectedOption !== null) {
            setValue("familySituation", selectedOption.value);
        } else {
            setValue("familySituation", undefined);
        }
    };

    const handleChangeNationality = selectedOption => {
        console.log("nationality", selectedOption);
        if (selectedOption !== null) {
            setValue("nationality", selectedOption.value);
        } else {
            setValue("nationality", undefined);
        }
    };

    const saveEdit = data => {
        console.log(JSON.stringify(data));
        console.log("all",JSON.stringify({...employee, ...data}));
        console.log(data);
        service.put(getHostByAppName(EMPLOYEE_NAME),context, callBackRes, callBackError, {...employee, ...data});
    };

    const callBackRes = (res) => {
        console.log("callBackRes save employee",res.data)
        setEmployee(res.data);
        setSelectObject({})
    }

    const callBackError = (error) => {
        console.log(error);
        setErrorMessage(error.toString());
    }

    const onSubmitInformation = dataInformation => {
        if(selectObject.organization) {
            setErrorMessage("")
            setEditInformation(false);
            console.log("dataInformation", dataInformation)
            let objectTemp = {
                ...dataInformation,
                ...selectObject,
                skillEmployeeType: {
                    ...employee.skillEmployeeType,
                    experienceLevel: selectObject.experienceLevel ? selectObject.experienceLevel : undefined
                },
                otherDataEmployeeType: {
                    ...employee.otherDataEmployeeType, ...dataInformation.otherDataEmployeeType,
                    mutual: selectObject.mutual ? selectObject.mutual : undefined
                }
            }
            console.log("save information", {...objectTemp})
            saveEdit({...objectTemp});
        }else{
            setErrorMessage("Champ organization est obligatoire")
        }
    };

    //
    //
    const onSubmitAdresse = dataAddress => {
        setEditAddress(false);
        saveEdit(dataAddress);
    };

    const onSubmitCivility = dataCivility => {
        setEditCivility(false);
        console.log("save Civility",{civilState : {...dataCivility.civilState, ...selectObject}})
        saveEdit({civilState : {...dataCivility.civilState, ...selectObject}});
    };
    //
    const onSubmitBankDetails = dataBankDetails => {
        setEditBankDetails(false);
        saveEdit(dataBankDetails);
    };

    const onSubmitContract = dataContracts => {
        console.log("in update contract", dataContracts)
        setEditContract(false);
        saveEdit({
            contract: {
                ...dataContracts.contract, ...selectObject, remuneration: {
                    annualSalary: parseFloat(dataContracts.contract.remuneration.annualSalary),
                    monthlySalary: parseFloat(dataContracts.contract.remuneration.monthlySalary),
                    dailySalary: parseFloat(dataContracts.contract.remuneration.dailySalary),
                    transportationAllowance: parseFloat(dataContracts.contract.remuneration.transportationAllowance),
                    mealAllowance: parseFloat(dataContracts.contract.remuneration.mealAllowance),
                    currency: dataContracts.contract.remuneration.currency
                }
            }
        });
    };


    const onSubmitList = data => {
        console.log("dataContacts",data)
        service.putSpe(getHostByAppName(EMPLOYEE_NAME),`${context}/updateEmergencyContacts/${employee.id}`, callBackRes, callBackError, data,()=>console.log(""));
    };

    const onSubmitDiplomas = data => {
        console.log("dataContacts",data)
        service.putSpe(getHostByAppName(EMPLOYEE_NAME),`${context}/updateDiplomas/${employee.id}`, callBackRes, callBackError, data,()=>console.log(""));
    };

    const onSubmitSkills = data => {
        console.log("dataContacts",data.map(obj => obj.key))
        service.putSpe(getHostByAppName(EMPLOYEE_NAME),`${context}/updateSkills/${employee.id}`, callBackRes, callBackError, data.map(obj => obj.key),()=>console.log(""));
    };

    const handleChangeActivityArea = selectedOption => {
        console.log("activityArea", selectedOption);
        setValue("activityArea", selectedOption.object);
        setReactSelect({selectedOption});
    };

    return (
        employee ?
        <Fragment>
                    <div className="row ma-0">
                        <div className="col-lg-12 ptb-10">
                            <div className="roe-card-style">
                                <div className="roe-card-header">

                                </div>
                                <div className="roe-card-body">
                                    <div>
                                        {!editInformation &&
                                        <LatestActivity
                                            icon={person}
                                            title={employee.civility+". "+employee.firstName+" "+employee.lastName}
                                            code={employee.code}
                                            email={employee.contactInformation && employee.contactInformation.email ? employee.contactInformation.email : "ND"}
                                            telephone={employee.contactInformation !== undefined ? employee.contactInformation.mobile : "ND"}
                                            statut={employee.enable === true ? "Activé" : "Désactivé"}
                                            categ={employee.category}
                                            func={employee.function}
                                            organ={employee.organization}
                                            mut={employee.otherDataEmployeeType && employee.otherDataEmployeeType.mutual ? employee.otherDataEmployeeType.mutual : null}
                                            experience={employee.skillEmployeeType && employee.skillEmployeeType.experienceLevel ? employee.skillEmployeeType.experienceLevel : null}
                                            secnum={employee.socialSecurityNumber ? employee.socialSecurityNumber :"ND"}
                                        />
                                        }

                                        {!editInformation && <DisplayInformationStyle icon={""}
                                                                                      title={""}
                                                                                      inf1={{
                                                                                          title: "Date visite Médicale",
                                                                                          value: employee.otherDataEmployeeType && employee.otherDataEmployeeType.medicalExaminationDate ? employee.otherDataEmployeeType.medicalExaminationDate : "ND"
                                                                                      }}
                                                                                      inf2={{
                                                                                          title: "Véhicule",
                                                                                          value: employee.otherDataEmployeeType && employee.otherDataEmployeeType.vehicle ? "Oui" : "Non"
                                                                                      }}
                                                                                      inf3={{
                                                                                          title: "Mobilité Ville",
                                                                                          value: employee.otherDataEmployeeType && employee.otherDataEmployeeType.cityMobility ? employee.otherDataEmployeeType.cityMobility : "N.D"
                                                                                      }}
                                                                                      inf5={{
                                                                                          title: "Disponibilité",
                                                                                          value: employee.otherDataEmployeeType && employee.otherDataEmployeeType.available ? "Oui" : "Non"
                                                                                      }}
                                                                                      inf6={{
                                                                                          title: "Interne",
                                                                                          value: employee.otherDataEmployeeType && employee.otherDataEmployeeType.internal ? "Oui" : "Non"
                                                                                      }}
                                        />
                                        }
                                        <form onSubmit={handleSubmit(onSubmitInformation)}>
                                            {editInformation &&

                                            <div className="roe-card-style col-sm-12 row">
                                                <div className="roe-card-body col-sm-4">
                                                    <div className="pl-5 pt-5 aligner-wrapper text-center">
                                                        <img className="pt-5"
                                                             src={identity}
                                                             style={{width:"180px"}}
                                                        />
                                                        <div style={{marginTop:"10px"}}>
                                                            <h3 className="card-title fs-25 bold-text">Coordonnées Personnelles</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="roe-card-body col-sm-8">
                                                    <Label>
                                                        <p style={{color: "red"}}> {errorMessage}</p>
                                                    </Label>
                                            <InformationUpdate setValue={setValue} register={register}
                                                                      errors={errors} control={control}
                                                               employee={employee} edit={true} dispatch={dispatchSelectObject}/>


                                                </div></div>}
                                            <br/>
                                            <div>
                                                <Row>
                                                    <Col className="col-sm-2 ml-auto">
                                                        {!editInformation && (

                                                            <AdaButton
                                                            className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3 "
                                                            onClick={() => {
                                                            setEditInformation(true);
                                                            setCollapse(false);
                                                        }}
                                                            >
                                                            <IntlMessages id="action.common.edit"/>
                                                            </AdaButton>


                                                        )}


                                                        {editInformation && (
                                                            <FormGroup>
                                                                <AdaButton
                                                                    className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                    type="submit"
                                                                >
                                                                    <IntlMessages id="action.common.submit"/>
                                                                </AdaButton>
                                                                <AdaButton
                                                                    className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                    onClick={() => setEditInformation(false)}
                                                                >
                                                                    <IntlMessages id="action.common.cancel"/>
                                                                </AdaButton>

                                                            </FormGroup>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Accordion section*/}
                    <div className="col-lg-12">
                        <Accordion edit={editCivility} title={<IntlMessages id="employee.civilite"/>}>
                            <div className="row ma-0">
                                <br/>
                                <div className="col-lg-12 ptb-10">
                                    <div className="roe-card-style">
                                        <div className="roe-card-header"/>
                                        <div className="roe-card-body">
                                            <div>
                                                {!editCivility && <EmployeeCivility employee={employee}/>}
                                                <form onSubmit={handleSubmit(onSubmitCivility)}>
                                                    {editCivility &&

                                                    <div className="roe-card-style col-sm-12 row">
                                                        <div className="roe-card-body col-sm-4">
                                                            <div className="pl-5 pt-2 aligner-wrapper text-center">
                                                                <img className="pt-2"
                                                                     src={etatcivil}
                                                                     style={{width:"180px"}}
                                                                />
                                                                <div style={{marginTop:"10px"}}>
                                                                    <h3 className="card-title fs-25 bold-text">Etat Civil</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="roe-card-body col-sm-8">
                                                    <CivilityUpdate setValue={setValue} register={register}
                                                                           errors={errors} control={control}
                                                                           employee={employee} edit={true} dispatch={dispatchSelectObject}/>

                                                        </div></div>
                                                                           }


                                                    <br/>
                                                    <div>

                                                        <Row>
                                                            <Col className="col-sm-2 ml-auto">
                                                                {!editCivility && (

                                                                    <AdaButton
                                                                        className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3 "
                                                                        onClick={() => {
                                                                            setEditCivility(true);
                                                                        }}
                                                                    >
                                                                        <IntlMessages id="action.common.edit"/>
                                                                    </AdaButton>


                                                                )}


                                                                {editCivility && (
                                                                    <FormGroup>
                                                                        <AdaButton
                                                                            className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                            type="submit"
                                                                        >
                                                                            <IntlMessages id="action.common.submit"/>
                                                                        </AdaButton>
                                                                        <AdaButton
                                                                            className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                            onClick={() => setEditCivility(false)}
                                                                        >
                                                                            <IntlMessages id="action.common.cancel"/>
                                                                        </AdaButton>

                                                                    </FormGroup>
                                                                )}
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </div>

                    <div className="col-lg-12 ">
                        <Accordion edit={editAddress} title={<IntlMessages id="common.adresse.postal"/>}>
                            <div className="row ma-0">
                                <br/>
                                <div className="col-lg-12 ptb-10">
                                    <div className="roe-card-style">
                                        <div className="roe-card-header"/>
                                        <div className="roe-card-body">
                                            <div>
                                                {!editAddress && (
                                                    <WrapperInformation title={"Adresse postal"}
                                                                        icon={addressPostal}
                                                                        informationLigne1={employee.address ? (employee.address.number + " " + employee.address.street):"ND"}
                                                                        informationLigne2={employee.address ? (employee.address.zipCode + " " + employee.address.city + " " +employee.address.country):"ND"}/>
                                                )}
                                                <form onSubmit={handleSubmit(onSubmitAdresse)}>
                                                    {editAddress &&

                                                    <div className="roe-card-style col-sm-12 row">
                                                        <div className="roe-card-body col-sm-4">
                                                            <div className="pl-5 pt-2 aligner-wrapper text-center">
                                                                <img className="pt-2"
                                                                     src={addressPostal}
                                                                     style={{width:"180px"}}
                                                                />
                                                                <div style={{marginTop:"10px"}}>
                                                                    <h3 className="card-title fs-25 bold-text">Adresse postal</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="roe-card-body col-sm-8 pt-3">

                                                    <AdaAddress setValue={setValue} register={register}
                                                                errors={errors}
                                                                address={employee !== undefined ? employee.address : {}}
                                                                edit={true}/>

                                                        </div></div>
                                                                }
                                                    <div>
                                                        <Row>
                                                            <Col className="col-sm-2 ml-auto pt-4">
                                                                {!editAddress && (

                                                                    <AdaButton
                                                                        className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3 "
                                                                        onClick={() => {
                                                                            setEditAddress(true);
                                                                        }}
                                                                    >
                                                                        <IntlMessages id="action.common.edit"/>
                                                                    </AdaButton>


                                                                )}


                                                                {editAddress && (
                                                                    <FormGroup>
                                                                        <AdaButton
                                                                            className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                            type="submit"
                                                                        >
                                                                            <IntlMessages id="action.common.submit"/>
                                                                        </AdaButton>
                                                                        <AdaButton
                                                                            className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                            onClick={() => setEditAddress(false)}
                                                                        >
                                                                            <IntlMessages id="action.common.cancel"/>
                                                                        </AdaButton>

                                                                    </FormGroup>
                                                                )}
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </div>

                    <div className="col-lg-12 ">
                        <Accordion edit={editBankDetails} title={<IntlMessages id="common.cord.bancaires"/>}>
                            <div className="row ma-0">
                                <div className="col-lg-12 ptb-10">
                                    <div className="roe-card-style">
                                        <div className="roe-card-header"/>
                                        <div className="roe-card-body">
                                            <div>
                                                {!editBankDetails && (
                                                    <EmployeeBank employee={employee}/>
                                                )}
                                                <form onSubmit={handleSubmit(onSubmitBankDetails)}>
                                                    {editBankDetails &&

                                                    <div className="roe-card-style col-sm-12 row">
                                                        <div className="roe-card-body col-sm-4">
                                                            <div className="pl-5 pt-2 aligner-wrapper text-center">
                                                                <img className="pt-2"
                                                                     src={infoban}
                                                                     style={{width:"180px"}}
                                                                />
                                                                <div style={{marginTop:"10px"}}>
                                                                    <h3 className="card-title fs-25 bold-text">Coordonnées bancaires</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="roe-card-body col-sm-8 pt-3">
                                                    <BankModelEmployee setValue={setValue} register={register}
                                                                   errors={errors}
                                                                   bankDetails={employee !== undefined ? employee.bankDetails : {}}
                                                                   edit={true}/>

                                                        </div></div>
                                                                   }
                                                    <div>

                                                        <Row>
                                                            <Col className="col-sm-2 ml-auto pt-4">
                                                                {!editBankDetails && (

                                                                    <AdaButton
                                                                        className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3 "
                                                                        onClick={() => {
                                                                            setEditBankDetails(true);
                                                                        }}
                                                                    >
                                                                        <IntlMessages id="action.common.edit"/>
                                                                    </AdaButton>


                                                                )}


                                                                {editBankDetails && (
                                                                    <FormGroup>
                                                                        <AdaButton
                                                                            className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                            type="submit"
                                                                        >
                                                                            <IntlMessages id="action.common.submit"/>
                                                                        </AdaButton>
                                                                        <AdaButton
                                                                            className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                            onClick={() => setEditBankDetails(false)}
                                                                        >
                                                                            <IntlMessages id="action.common.cancel"/>
                                                                        </AdaButton>

                                                                    </FormGroup>
                                                                )}
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </div>

                    <div className="col-lg-12 ">
                        <Accordion edit={editContract} title={<IntlMessages id="employee.contract"/>}>
                            <div className="row ma-0">
                                <div className="col-lg-12 ptb-10">
                                    <div className="roe-card-style">
                                        <div className="roe-card-header"/>
                                        <div className="roe-card-body">

                                            <div>
                                                {!editContract && (
                                                    <EmployeeContract employee={employee}/>
                                                )}

                                                    <form onSubmit={handleSubmit(onSubmitContract)}>
                                                        <div>
                                                            {editContract && (

                                                            <UpdateContract errors={errors} register={register}
                                                                                   setValue={setValue} dispatch={dispatchSelectObject} control={control}
                                                                                   employee={employee} edit={true}/>
                                                            )}

                                                            <div>

                                                                <Row>
                                                                    <Col className="col-sm-2 ml-auto pt-4">
                                                                        {!editContract && (

                                                                            <AdaButton
                                                                                className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3 "
                                                                                onClick={() => {
                                                                                    setEditContract(true);
                                                                                }}
                                                                            >
                                                                                <IntlMessages id="action.common.edit"/>
                                                                            </AdaButton>


                                                                        )}


                                                                        {editContract && (
                                                                            <FormGroup>
                                                                                <AdaButton
                                                                                    className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                                    type="submit"
                                                                                >
                                                                                    <IntlMessages id="action.common.submit"/>
                                                                                </AdaButton>
                                                                                <AdaButton
                                                                                    className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                                    onClick={() => setEditContract(false)}
                                                                                >
                                                                                    <IntlMessages id="action.common.cancel"/>
                                                                                </AdaButton>

                                                                            </FormGroup>
                                                                        )}
                                                                    </Col>
                                                                </Row>

                                                            </div>

                                                        </div>

                                                    </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </div>

                    <div className="col-lg-12 ">
                        <Accordion edit={editContact} title={<IntlMessages id="employee.skills"/>}>
                            <div className="row ma-0">
                                <div className="col-lg-12 ptb-10">
                                    <div className="roe-card-style">
                                        <div className="roe-card-header"/>
                                        <div className="roe-card-body">
                                            <div>
                                                    <EmployeeSkills employee={employee} onSubmitSkills={onSubmitSkills}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </div>

                    <div className="col-lg-12 ">
                        <Accordion edit={editContact} title={<IntlMessages id="employee.diplomas"/>}>
                            <div className="row ma-0">
                                <div className="col-lg-12 ptb-10">
                                    <div className="roe-card-style">
                                        <div className="roe-card-header"/>
                                        <div className="roe-card-body">
                                            <div>
                                                    <EmployeeDiplomas employee={employee} edit={true} onSubmitList={onSubmitDiplomas}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </div>

                    <div className="col-lg-12 ">
                        <Accordion edit={editContact} title={<IntlMessages id="employee.documents"/>}>
                            <div className="row ma-0">
                                <div className="col-lg-12 ptb-10">
                                    <div className="roe-card-style">
                                        <div className="roe-card-header"/>
                                        <div className="roe-card-body">
                                            <div>

                                                <Row>
                                                    <Col className="col-sm-2 ml-auto pt-4 mb-3">
                                                        <UploadAction
                                                            setObjectResource={setEmployee}
                                                            setModalSuccessShow={setEditDocuments}
                                                            objectResource={employee}
                                                            setErrorMessage={setErrorMessage}
                                                            context={context}
                                                        />

                                                    </Col>
                                                </Row>
                                                    <Documents objectResource={employee} setObjectResource={setEmployee}
                                                               context={context}  reload={editDocuments}/>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </div>

                    <div className="col-lg-12 ">
                        <Accordion edit={editContact} title={<IntlMessages id="common.contacts"/>}>
                            <div className="row ma-0">
                                <div className="col-lg-12 ptb-10">
                                    <div className="roe-card-style">
                                        <div className="roe-card-header"/>
                                        <div className="roe-card-body">
                                            <div>
                                                <EmployeeContactsUpdate employee={employee} edit={true} onSubmitList={onSubmitList}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </div>

                    <GenericSuccessModal show={modal} onHide={() => setModal(false)}/>
                </Fragment> : <IntlMessages id="employee.notfound"/>

    );
};

export default AdaEmployeeUpdate;
