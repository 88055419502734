import React, {useEffect, useState} from "react";
import {loginBack, iconDemo} from "../../../helper/constant";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import enhancer from "./enhancer/RegisterFormEnhancer";
import axios from "axios";
import Label from "reactstrap/es/Label";
import {useHistory} from "react-router-dom";
import {CustomInput, FormGroup} from "reactstrap";
import {AdaInputText} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";

const Validate = props => {

    let history = useHistory();
    let fileReader;

    const {register, handleSubmit, errors, setValue} = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [contents, setContents] = React.useState("");


    const [invalidFile, setInvalidFile] = useState(false);
    const [fileName, setFileName] = useState("");

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tokenAccount = params.get('tokenAccount');
    const username = params.get('username');
    const id = params.get('id');

    useEffect(() => {
        register({name: "name"});
        register({name: "type"});
        register({name: "lastModified"});
        register({name: "lastModifiedDateDocument"});
        register({name: "size"});
        register({name: "contents"});
        register({name: "password1"});
        register({name: "password2"});
    }, []);

    const handleFileRead = e => {
        let contents = fileReader.result;
        setValue("contents", contents);
        setContents(contents);
    };

    const handleFileChange = ({target: {files}}) => {
        const cancel = !files.length;
        if (cancel) return;

        const [{size, name}] = files;
        const maxSize = 1000000;

        if (size < maxSize) {
            setFileName(name);
            setInvalidFile(false);
        } else {
            setFileName("");
            setInvalidFile(true);
            return;
        }

        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsDataURL(files[0]);
        setValue("name", files[0].name);
        setValue("type", files[0].type);
        setValue("lastModified", files[0].lastModified);
        setValue("lastModifiedDateDocument", files[0].lastModifiedDate);
        setValue("size", files[0].size);
    };

    const onSubmit = data => {
        if (data.password1 === data.password2) {
            // upload img
            axios
                .post(`${process.env.REACT_APP_API_URL}/document-service/files/`,
                    {
                        "name": data.name,
                        "type": data.type,
                        "lastModified": data.lastModified,
                        "lastModifiedDateDocument": data.lastModifiedDateDocument,
                        "size": data.size,
                        "contents": data.contents,
                        "categoryFile": "PHOTO_IDENTITY",
                        "referenceId": id
                    },
                    {
                        headers: {
                            'Authorization': tokenAccount,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        }
                    })
                .then(res => {
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMessage(error.toString());
                });

            // change passsord
            axios
                .put(`${process.env.REACT_APP_API_URL}/iam-service/users/validate_user/${username}`,
                    {
                        "newPassword": data.password1
                    },
                    {
                        headers: {
                            'Authorization': tokenAccount,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        }
                    })
                .then(res => {
                    history.push(`/login`);
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMessage(error.toString());
                });
            props.history.push("/login");
        }
    };

    const loginContainer = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "fixed",
        overflow: "auto",
        top: 0,
        bottom: 0
    };


    return (
        <div className="container-fluid" style={loginContainer}>
            <div className="form-container">
                <div className="login-icon">
                    <img src={iconDemo} alt="icon" height="100px"/>
                </div>
                <div className="login-title">Validation de compte</div>
                <form className="pa-24" onSubmit={handleSubmit(onSubmit)}>
                    <Label>
                        <p style={{color: "red"}}> {errorMessage}</p>
                    </Label>
                    {contents && (<div>
                        <img
                            src={contents}
                            style={{width: "180px"}}
                        />
                    </div>)}
                    <FormGroup>
                        <label>Photo d'identité</label>
                        <CustomInput
                            type="file"
                            id="photo"
                            name="photo"
                            onChange={handleFileChange}
                            invalid={invalidFile}
                        />
                    </FormGroup>

                    <FormGroup>
                        Définir un nouveau mot de passe
                        <AdaInputText
                            name="password1"
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                    <FormGroup>
                        Définir un nouveau mot de passe
                        <AdaInputText
                            name="password2"
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>


                    <button type="submit" className="btn form-button">
                        Valider
                    </button>
                    <div
                        className="text-center link-label"
                        onClick={() => props.history.push("/login")}
                    >
                        Login ?
                    </div>
                </form>
            </div>

        </div>
    );
};

export default compose(
    withRouter,
    enhancer
)(Validate);
