import React, {Fragment} from "react";
import {Redirect} from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const ProtectedRoute = ({authData, children}) => {
    return (
        <div>
            {cookies.get('jwtTokenTemp') ? (
                <Redirect to={"/validate"}/>
            ) : (
                cookies.get('jwtToken') ? (
                    <Fragment>{children}</Fragment>
                ) : (
                    <Redirect to={"/login"}/>
                )
            )}
        </div>
    );
};
