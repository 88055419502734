import React, {useEffect, useState} from "react";
import DisplayInformation from "../../commun/displayInformation";
import IntlMessages from "../../../helpers/intlMessages";
import * as service from "../../../service/crud.service";
import {getHostByAppName, NOMENCLATURE_NAME} from "../../../service/host";
import {Col, Row} from "reactstrap";
import {contract, infor, rem} from "../../../helpers/constants";
import DisplayInformationStyle from "../updateEmployee/displayInformationStyle";

export default function EmployeeContract({employee}) {
    const [contractType, setContractType] = useState({});
    const [profStatus, setProfStatus] = useState({});
    const [activityType, setActivityType] = useState({});
    const [workingTimeModality, setWorkingTimeModality] = useState({});

    useEffect(
        () => {

           if(employee.contract && employee.contract.contractType){
                service.getById(
                    getHostByAppName(NOMENCLATURE_NAME),
                    `contract_types`,
                    setContractType,
                    error => console.log(error), employee.contract.contractType
                );
            }

            if(employee.contract && employee.contract.professionalStatus){
                service.getById(
                    getHostByAppName(NOMENCLATURE_NAME),
                    `professional_statuss`,
                    setProfStatus,
                    error => console.log(error), employee.contract.professionalStatus
                );
            }


            if(employee.contract && employee.contract.workingTimeModality){
                service.getById(
                    getHostByAppName(NOMENCLATURE_NAME),
                    `working_time_modalitys`,
                    setWorkingTimeModality,
                    error => console.log(error), employee.contract.workingTimeModality
                );
            }


            if(employee.contract && employee.contract.activityType){
                service.getById(
                    getHostByAppName(NOMENCLATURE_NAME),
                    `company_activity_areas`,
                    setActivityType,
                    error => console.log(error), employee.contract.activityType
                );
            }


        },
        [employee]
    );
    return (
        <div>


            <DisplayInformationStyle icon={contract}
                                     title={"Contrat"}
                                     inf1={{title : "Date début", value : employee.contract && employee.contract.beginDate ? employee.contract.beginDate : "ND"}}
                                     inf2={{title : "Type de contrat", value : contractType.name ? contractType.name : "ND"}}
                                     inf3={{title : "Modalité du temps de travail", value : workingTimeModality.name && workingTimeModality.name ? workingTimeModality.name : "ND"}}
                                     inf4={{title : "Qualification", value : employee.contract && employee.contract.qualification ? employee.contract.qualification : "ND"}}
                                     inf5={{title : "Type d'activité", value : activityType.name ? activityType.name : "ND"}}
                                     inf6={{title : "Statut professionnel", value : profStatus.name ? profStatus.name : "ND"}}
                                     inf7={{title : "Coefficient", value : employee.contract && employee.contract.coefficient ? employee.contract.coefficient : "ND"}}
            />

            <DisplayInformationStyle icon={rem}
                                     title={"Rémuneration"}
                                     inf1={{title : "Salaire annuel", value : employee.contract && employee.contract.remuneration && employee.contract.remuneration.annualSalary? employee.contract.remuneration.annualSalary+" "+employee.contract.remuneration.currency : "ND"}}
                                     inf2={{title : "Salaire mensuel", value : employee.contract && employee.contract.remuneration && employee.contract.remuneration.monthlySalary? employee.contract.remuneration.monthlySalary+" "+employee.contract.remuneration.currency : "ND"}}
                                     inf3={{title : "Salaire journalier", value : employee.contract && employee.contract.remuneration && employee.contract.remuneration.dailySalary? employee.contract.remuneration.dailySalary+" "+employee.contract.remuneration.currency : "ND"}}
                                     inf5={{title : "Indemnité de transport", value : employee.contract && employee.contract.remuneration && employee.contract.remuneration.transportationAllowance? employee.contract.remuneration.transportationAllowance+" "+employee.contract.remuneration.currency : "ND"}}
                                     inf6={{title : "Indemnité de repas", value : employee.contract && employee.contract.remuneration && employee.contract.remuneration.mealAllowance? employee.contract.remuneration.mealAllowance+" "+employee.contract.remuneration.currency : "ND"}}
            />

            <DisplayInformationStyle icon={infor}
                                     title={"Etat du contrat"}
                                     inf1={{title : "Période d'essai", value : employee.contract ? (employee.contract.trialPeriod ? "Oui" : "Non") : "ND"}}
                                     inf2={{title : "Extension de la période d'essai", value : employee.contract ? (employee.contract.extendedTrialPeriod ? "Oui" : "Non") : "ND"}}
                                     inf5={{title : "Fin de contrat", value : employee.contract &&  employee.contract.endDate ? "le "+employee.contract.endDate : "Non"}}
                                     inf6={{title : "Motif fin de contrat", value : employee.contract && employee.contract.reasonEndContract ? employee.contract.reasonEndContract : "ND"}}
            />

        </div>
    );
}
