import React, { useEffect, useState } from "react";
import DisplayInformation from "../../commun/displayInformation";
import * as service from "../../../service/crud.service";
import {getHostByAppName, NOMENCLATURE_NAME, ORGANIZATION_NAME} from "../../../service/host";
import {Col, Label, Row} from "reactstrap";
import IntlMessages from "../../../helpers/intlMessages";
import {element} from "prop-types";
import DisplayInformationStyle from "../updateEmployee/displayInformationStyle";
import {etatcivil} from "../../../helpers/constants";

export default function EmployeeCivility({employee}) {
  const [displayCivilityList, setDisplayCivilityList] = useState([]);
  const [nationalitiesList, setNationalitiesList] = useState("");
  const [birthCountry, setBirthCountry] = useState({});
  const [familySituation, setFamilySituation] = useState({});

  useEffect(
    () => {
      let displayList = [];
      displayList.push({
        label: "employee.birthDate",
        value:
          employee.civilState !== undefined ? employee.civilState.birthDate : ""
      });

        if(employee.civilState && employee.civilState.birthCountry){
            service.getById(
                getHostByAppName(NOMENCLATURE_NAME),
                `countrys`,
                setBirthCountry,
                error => console.log(error), employee.civilState.birthCountry
            );
        }

      displayList.push({
        label: "employee.birthCity",
        value:
          employee.civilState !== undefined ? employee.civilState.birthCity : ""
      });

      displayList.push({
        label: "employee.birthName",
        value:
          employee.civilState !== undefined ? employee.civilState.birthName : ""
      });

        if(employee.civilState && employee.civilState.familySituation){
            service.getById(
                getHostByAppName(NOMENCLATURE_NAME),
                `marital_statuss`,
                setFamilySituation,
                error => console.log(error), employee.civilState.familySituation
            );
        }

      let nationalities = "";
      if (employee.civilState !== null && employee.civilState !== undefined) {
        if (employee.civilState.nationalities != null) {
            service.get(
                getHostByAppName(NOMENCLATURE_NAME),
                `countrys`,
                filterData,
                error => console.log(error)
            )
        }
      }

      setDisplayCivilityList(displayList);
    },
    [employee]
  );

  const filterData = data =>{
      console.log("data",data)
      let tab = "";
      data.forEach(element=> {
          employee.civilState.nationalities.forEach(id => {
              if(element.id === id){
                  tab= tab+getNewTab(tab, element.name);
              }
          })
      });

      console.log("tab",tab)
      setNationalitiesList(tab)
  }

  const getNewTab = (tab, name) =>{
      if(tab === ""){
          return name;
      }else{
          return " / "+name;
      }
  }

    return (
        <div>

            <DisplayInformationStyle icon={etatcivil}
                                     title={"Etat Civil"}
                                     inf1={{title : "Date de naissance", value : employee.civilState && employee.civilState.birthDate ? employee.civilState.birthDate : "ND"}}
                                     inf2={{title : "Pays de naissance", value :birthCountry.name ? birthCountry.name : "ND"}}
                                     inf3={{title : "Ville", value : employee.civilState && employee.civilState.birthCity ? employee.civilState.birthCity : "ND"}}
                                     inf5={{title : "Nom de naissance", value : employee.civilState && employee.civilState.birthName ? employee.civilState.birthName : "ND"}}
                                     inf6={{title : "situation familiale", value : familySituation.name ? familySituation.name : "ND"}}
                                     inf7={{title : "Nationalité", value : nationalitiesList ? nationalitiesList : "ND"}}
/>

        </div>
    );
}
