import React from 'react';
import PageTitle from '../components/common/PageTitle';
import axios from "axios";
import {Col, Row} from "reactstrap";
import {getConfigObject} from "@adaming/ada-react-component";
import classNames from "classnames";
import {getOrganizationSelected} from "@adaming/ada-react-component";

export default function Menu(props) {

    const [applicationsModules, setApplicationModules] = React.useState([]);
    const {REACT_APP_API_URL, REACT_APP_DOMAIN} = process.env;
    const [organization, setOrganization] = React.useState();

    const titleStyle = {
        background: "#b71c1c",
        color: "#FFF",
        boxShadow: `0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px ${
            "#f6a821"
            }`
    };
    const titleColor = {
        color: "#b71c1c"
    };

    React.useEffect(() => {

        axios.get(`${REACT_APP_API_URL}/iam-service/business_domains/with_application_module_secured`, getConfigObject()).then(res => {
            if(res.data[0] && res.data[0].liste){
                res.data[0].liste = res.data[0].liste.filter(element => !element.includes("application-inti"));
            }
            setApplicationModules(res.data);
        });

        let org = getOrganizationSelected();
        setOrganization(org !== undefined ? org.name : "Veuillez séléctionner une organization");

    }, []);

    return (
        <div className="col-lg-12">
            <div className="col-lg-10">
                <div className={classNames("Page-title", "ptb-15")}>
                    <div className="title-icon" style={titleStyle}>
                        {organization && organization.substr(organization.indexOf(".") + 1).substring(0, 2)}
                    </div>
                    <div style={titleColor} className="title flex-1 fs-16 bold-text">
                        {organization}
                    </div>
                </div>
                <div className="col-lg-12">
                    <Row>
                        {
                            applicationsModules && applicationsModules.map((prop, key) => (


                                        prop.liste.map((elt, key) => (
                                            <Col lg="3" key={`module${key}`}>
                                            <div className="introduction" key={`itemListe${key}`}>

                                                <div>
                                                    <div
                                                        className="whitelight pa-10 cursor-pointer with-transition roe-box-shadow pos-relative board-grid">
                                                        <div className="board" style={{background: "#eeeeee"}}>
                                                            <div className="overlay flex-x center">
                                                                <div className="text-center" onClick={() => window.open('https://' + elt.split("|")[1] + '.' + REACT_APP_DOMAIN + '/', "_parent")}>
                                                                    <div
                                                                        className="fs-20 bold-text px15 board-list-title"
                                                                        style={{
                                                                            wordBreak: "break-all",
                                                                            padding: "0px 15px"
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <i
                                                                                className={"icon fas " + elt.split("|")[3]}
                                                                                style={{fontSize: 45}}
                                                                            />
                                                                        </div>
                                                                        <div style={{color: "#3230d6", fontSize: 15}}>
                                                                            {elt.split("|")[2]}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
<br/>
                                            </div>
                                            </Col>
                                        ))

                            ))
                        }
                    </Row>
                </div>
            </div>

        </div>
    );
}
