import React, { useEffect, useState } from "react";
import { FormGroup, Row } from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import { AdaButton, AdaInputText, AdaSelect } from "@adaming/ada-react-component";
import * as specificService from "../../../service/employee.service";
import {
  ASSIGNMENT_NAME,
  CRM_NAME,
  EMPLOYEE_NAME,
  getHostByAppName,
  NOMENCLATURE_NAME
} from "../../../service/host";
import {Controller, useForm} from "react-hook-form";
import * as service from "../../../service/crud.service";

export default function EmployeeSkills({employee, onSubmitSkills, edit=true}) {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    reset,
    control,
    unregister
  } = useForm();
  const [skillsList, setSkillsList] = useState([]);
  const [skillsSelectedList, setSkillsSelectedList] = useState([]);
  const [skillObj, setSkillObj] = useState({
    key: "",
    label: "",
    value: ""
  });
  const [messageFact, setMessageFact] = useState(null);

  useEffect(() => {
      register({name: "bskills"});
    service.getAllForSelect(
      getHostByAppName(NOMENCLATURE_NAME),
      `skills`,
        callBackBillingAddOns,
      error => console.log(error)
    );

  }, [employee]);

  const callBackBillingAddOns = data =>{
      console.log("in employee skills",employee)
      if(employee && employee.id && employee.skillEmployeeType && employee.skillEmployeeType.skills && employee.skillEmployeeType.skills.length > 0){
          setValue("skills", employee.billingAddOns);
          setSkillsSelectedList(data.filter(objData => employee.skillEmployeeType.skills.find(idSelected => idSelected === objData.key)))
          setSkillsList(data.filter(objData => !employee.skillEmployeeType.skills.find(idSelected => idSelected === objData.key)))
      }else{
          setSkillsList(data)
      }
  }

  const handleChangeSelect = selectedOptionTab => {
    let selectedOption = selectedOptionTab[0];
    console.log("skl", selectedOption);
    if (selectedOption !== null) {
      setValue("skl", selectedOption.key);
      setSkillObj({
        ...skillObj,
        key: selectedOption.key,
        label: selectedOption.label
      });
    } else {
      setValue("skl", null);
    }
  };


  const addElement = () => {
    console.log("in add element", skillObj);
    if (skillObj.key ) {
      setSkillsList(
        skillsList.filter(obj => obj.key !== skillObj.key)
      );
      let list = skillsSelectedList.concat(skillObj);
      setSkillsSelectedList(list);
      onSubmitSkills(list);
      setSkillObj({ key: "", label: "", value: "" });
      setValue("skl", null);
      setValue("value", "");
      setValue("skills", list);
      setMessageFact(null);
    } else {
      setMessageFact("Champs obligatoire");
    }
  };

  const deleteElement = obj => {
    console.log("in delete element", obj);
    setSkillsList(skillsList.concat(obj));
    let list = skillsSelectedList.filter(obj1 => obj1.key !== obj.key)
    setSkillsSelectedList(list);
    onSubmitSkills(list);
      setValue("skills", list);
  };

  return (
    <div className="roe-card-body col-sm-12"  style={{
      maxHeight: '210px',
      minHeight: '200px',
      overflowY: 'auto'
    }}>
      {edit && <Row>
        <Col className="col-sm-5">
          <IntlMessages id="skills.list" />
          <Controller
            as={<AdaSelect name={"skl"} />}
            name={"skl"}
            isClearable
            errors={errors}
            options={skillsList}
            onChange={handleChangeSelect}
            control={control}
            innerRef={register}
          />
        </Col>

        <Col className="col-sm-3">
          <Row>
            <Col className="mt-3">
              <Row >
                <Col className="col-3 mt-2">
                  <div className="c-btn ma-5 c-warning">
                    <i
                      className="fa fa-plus"
                      style={{ fontSize: 15 }}
                      onClick={addElement}
                    />
                  </div>
                </Col>
                <Col className="col-8 mt-2">
                  <div className="display-7 c-text-danger">{messageFact}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>}
      <br />
      <Row>
        {skillsSelectedList.map(obj => (
            <Col xs={4} className="mb-3">
              <Row className="col-sm-9">
                <Col className="col-sm-8 mt-1">
                  <span className="chip">
                      {obj.label}
                    </span>
                </Col>
                <Col>
                  {edit && <div className="c-btn c-danger">
                  <i
                      className="fas fa-trash"
                      style={{ fontSize: 7}}
                      onClick={() => deleteElement(obj)}
                  />
                  </div>}
                </Col>
              </Row>

            </Col>

        ))}
      </Row>

    </div>
  );
}
